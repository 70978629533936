import { Box, BoxProps, Grid, Stack, Typography, styled } from "@mui/material";
import {
  Attachment,
  Img,
  LXsText,
  Row,
  SmTextMd,
  StyledA,
  TimeDiff,
  XsText,
  XsTextMd,
} from "components";
import { DIRECT_PATHS } from "constants/routes";
import dayjs from "dayjs";
import { memo, useState } from "react";
import { useIntl } from "react-intl";
import { RFQType } from "types/rfq";
import CountryFlag from "./CountryFlag";
import { CrownTooltip, VerifyRFQTooltip } from "./Tooltip";
import useUser from "hooks/useUser";
import { formatImg } from "helpers/format";
import { QuotationType } from "types/quotaion";
import RFQReviewButton from "./RFQReviewButton";
import { DubbleCheckIcon } from "assets/icons";

type Props = {
  rfq: RFQType;
  reviewQuote?: QuotationType;
  hideButton?: boolean;
  sx?: BoxProps["sx"];
};

const RFQ = ({ rfq, sx, reviewQuote, hideButton }: Props) => {
  const t = useIntl();
  const {
    name,
    product_name,
    quantity,
    unit,
    posted_at,
    product_category,
    payment_term,
    destination_port,
    customer,
    attachment = [],
    is_featured,
    fd_country,
    country_code,
    trade_term,
    is_verified,
    is_seen,
    customer_image,
    customer_id,
    quotation_id,
  } = rfq;
  const NOW = dayjs();
  const { isLoggedIn } = useUser();
  const [isSeen] = useState(is_seen === 1);

  const infors = [
    {
      label: "product",
      value: product_category?.label,
      hide: !product_category?.value,
      link: DIRECT_PATHS.categoryRfqs(product_category?.value),
    },
    {
      label: "quantity",
      value: (quantity || "") + " " + (unit?.label || ""),
      hide: !quantity,
    },
    { label: "payment_terms", value: payment_term, hide: !payment_term },
    { label: "trade_terms", value: trade_term, hide: !trade_term },
    {
      label: "destination_port",
      value: destination_port,
      hide: !destination_port,
    },
  ];

  return (
    <StyledRFQ className={isSeen && isLoggedIn ? "is-seen" : ""} sx={sx}>
      <Row spacing={2}>
        <Box
          flex={1}
          maxWidth={attachment.length > 0 ? "calc(100% - 48px)" : "100%"}
          overflow="hidden"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledA href={DIRECT_PATHS.rfqDetail(name)}>
              <SmTextMd className="title cutoff-text oneline" component="h2">
                {product_name}
              </SmTextMd>
            </StyledA>
            {is_featured === 1 && (
              <CrownTooltip
                title={t.formatMessage({
                  id: "this_is_a_premium_sourcing_request",
                })}
              />
            )}
            {is_verified === 1 && <VerifyRFQTooltip isVerified={true} />}
          </Stack>

          <Stack
            direction="row"
            spacing={1.25}
            alignItems="center"
            mt="6px"
            mb="6px"
          >
            <Row spacing={0.5} flex={1}>
              <XsText className="request-by">
                {t.formatMessage({ id: "buyer" })}
              </XsText>
              {customer_image && (
                <Img
                  src={formatImg(customer_image, 1)}
                  alt="img"
                  className="avatar"
                />
              )}
              <StyledA href={DIRECT_PATHS.buyerFullDetail(customer_id)}>
                <XsTextMd className="customer-name ellipsis-text">
                  {customer}
                </XsTextMd>
              </StyledA>
            </Row>
            {fd_country && (
              <div className="flag">
                <Row spacing={1}>
                  <XsText className="request-by">
                    {t.formatMessage({ id: "import_to" })}
                  </XsText>
                  <CountryFlag
                    country={fd_country}
                    {...{ country_code }}
                    maxWidth={84}
                  />
                </Row>
              </div>
            )}
          </Stack>
        </Box>

        {attachment.length > 0 && <Attachment attachment={attachment[0].url} />}
      </Row>
      <div style={{ height: hideButton ? "auto" : 84 }}>
        <Grid container spacing="10px">
          {infors
            .filter((info) => !info.hide)
            .map((info, i) => (
              <Grid item xs={6} key={i}>
                <LXsText
                  className="info-text ellipsis-text"
                  component={info.link ? "h3" : "p"}
                >
                  {t.formatMessage({ id: info.label })}:{" "}
                  {info.link ? (
                    <StyledA
                      style={{ color: "var(--sub-color)" }}
                      onClick={(e) => e.stopPropagation()}
                      href={info.link}
                    >
                      {info.value}
                    </StyledA>
                  ) : (
                    <span>{info.value}</span>
                  )}
                </LXsText>
              </Grid>
            ))}
        </Grid>
      </div>

      <Stack spacing={0.5} mt="10px">
        {!hideButton && (
          <>
            {reviewQuote && (
              <RFQReviewButton
                {...{ reviewQuote }}
                rfqId={name}
                quotationId={quotation_id}
              />
            )}
          </>
        )}

        <Row justifyContent="space-between" mt="6px" className="time-box">
          <Typography>
            <TimeDiff startTime={posted_at} endTime={NOW.format()} />
            {t.formatMessage({ id: "ago" })}
          </Typography>
          {isSeen && isLoggedIn && (
            <Row spacing={0.5}>
              <DubbleCheckIcon />
              <LXsText align="center">
                {t.formatMessage({ id: "seen" })}
              </LXsText>
            </Row>
          )}
        </Row>
      </Stack>
    </StyledRFQ>
  );
};

const StyledRFQ = styled(Box)(({ theme }) => ({
  // cursor: "pointer",
  border: "1px solid #EAECF0",
  borderRadius: 8,
  padding: "12px 18px 15px 18px",
  backgroundColor: "#fff",
  "&.is-seen": {
    backgroundColor: "#F2F4F7",
  },
  ".rfq_image": {
    width: 120,
    height: 120,
    objectFit: "cover",
  },
  ".title": {},
  ".request-by": {
    color: "var(--gray-color)",
    span: {},
    // maxWidth: "50%",
    whiteSpace: "nowrap",
  },
  ".details": {
    fontSize: 13,
    color: "var(--gray-900)",
  },
  ".customer-name": {
    maxWidth: 74,
  },
  ".avatar": {
    width: 16,
    height: 16,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".flag": {
    flex: 1,
  },
  ".quote_button": {
    maxWidth: "auto",
    lineHeight: 1.25,
  },
  ".time-box p": {
    fontSize: 13,
    color: "#27272A",
  },
  ".tooltip-icon img": {
    minWidth: 16,
  },
  ".info-text": {
    color: "var(--gray)",
    span: {
      color: "#27272A",
    },
  },
  ".attachment": {
    width: 48,
    height: 48,
    minWidth: 48,
  },
  [theme.breakpoints.down("md")]: {
    padding: 16,
    ".quote_button": {},
  },
}));

export default memo(RFQ);
