import { BaseButton, YellowButton } from "./Button";
import { useIntl } from "react-intl";
import { Dialog, DialogProps, Stack, styled } from "@mui/material";
import { LgTextSemiBold, SmText } from "./Text";
import { COLORS } from "constants/layout";
import { QuotationType } from "types/quotaion";
import Quotation from "./Quotation";
import Row from "./Row";

type Props = {
  reviewQuote?: QuotationType;
  onClickSendQuotation: () => void;
};

const RFQReview = ({
  reviewQuote,
  onClickSendQuotation,
  ...rest
}: Props & DialogProps) => {
  const t = useIntl();

  return (
    <StyledDialog
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 720,
          m: 2,
        },
      }}
      {...rest}
    >
      <Stack p={2} spacing={2}>
        <Stack spacing={1}>
          <LgTextSemiBold>
            {t.formatMessage({ id: "ready_send_quote" })}
          </LgTextSemiBold>
          <SmText color={COLORS.gray[500]}>
            {t.formatMessage({ id: "review_quote_details" })}
          </SmText>
        </Stack>
        {reviewQuote && (
          <Quotation
            quotation={reviewQuote}
            p={0}
            boxShadow="none"
            hideActions
          />
        )}
        <Row justifyContent="flex-end" className="buttons">
          <BaseButton onClick={(e) => rest?.onClose?.(e, "escapeKeyDown")}>
            {t.formatMessage({ id: "go_back" })}
          </BaseButton>
          <YellowButton onClick={onClickSendQuotation}>
            {t.formatMessage({ id: "send_quotation" })}
          </YellowButton>
        </Row>
      </Stack>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".cost": {},
  ".buttons": {
    button: {
      width: "fit-content",
      height: 44,
    },
  },
  [theme.breakpoints.down("md")]: {
    ".buttons": {
      button: {
        height: 40,
      },
    },
  },
}));

export default RFQReview;
