import { Box, BoxProps, Stack, Typography, styled } from "@mui/material";

import {
  Attachment,
  DotStatus,
  HtmlViewer,
  Row,
  SendMessageButton,
  SmTextBold,
  StyledATag,
  TimeDiff,
  XsText,
} from "components";
import { COLORS } from "constants/layout";
import { DIRECT_PATHS } from "constants/routes";
import dayjs from "dayjs";
import { formatImg } from "helpers/format";
import { formatCurrencyWithUnit } from "helpers/string";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { QuotationType } from "types/quotaion";

const Quotation = ({
  quotation,
  hideActions,
  ...rest
}: {
  quotation: QuotationType;
  hideActions?: boolean;
} & BoxProps) => {
  const t = useIntl();
  const { unit, unit_price, posted_at } = quotation;

  const Component = () => (
    <StyledQuotation
      className="quotation-card"
      p={2}
      boxShadow="0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)"
      {...rest}
    >
      <Row alignItems="flex-start">
        <img
          className="seller_img"
          src={formatImg(quotation.farm_avatar, 2)}
          alt="img"
        />
        <Box flex={1}>
          <Typography className="seller_name">
            {quotation?.user_full_name}
          </Typography>
          <Typography className="seller_desc">
            {quotation?.farm_name}
          </Typography>
          <Stack spacing={0.5}>
            <Typography className="product_name">{quotation?.title}</Typography>
            <HtmlViewer
              content={quotation.details}
              fontSize={12}
              color="var(--gray)"
            />
            <SmTextBold color={COLORS.gray[500]}>
              {unit_price &&
                `${formatCurrencyWithUnit(unit_price, "USD", "end")} /${
                  unit?.label || ""
                }`}
            </SmTextBold>
            {posted_at && (
              <XsText color={COLORS.gray[500]}>
                {t.formatMessage({ id: "date_posted" })}:{" "}
                <TimeDiff
                  startTime={dayjs(posted_at).format()}
                  endTime={dayjs().format()}
                />
              </XsText>
            )}
            <Stack gap={1} direction="row" flexWrap="wrap">
              {quotation?.media?.map((media, i) => (
                <Attachment attachment={media.url} key={i} />
              ))}
            </Stack>
          </Stack>

          {!hideActions && (
            <Row justifyContent="flex-end">
              <DotStatus status={{ value: "0", label: "delivered" }} />
              <SendMessageButton identity={quotation.identity} hideIcon />
            </Row>
          )}
        </Box>
      </Row>
    </StyledQuotation>
  );

  if (hideActions) return <Component />;

  return (
    <StyledATag href={DIRECT_PATHS.myQuotations(true)}>
      <Component />
    </StyledATag>
  );
};

const StyledQuotation = styled(Box)({
  borderRadius: 8,
  backgroundColor: "#fff",
  ".seller_img": {
    width: 32,
    height: 32,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".seller_name": {
    fontSize: 18,
    fontWeight: 600,
  },
  ".seller_desc, .product_desc, .quantity_text": {
    color: "var(--gray-color)",
  },
  ".product_name": {
    marginTop: 16,
    fontWeight: 700,
  },
  ".quantity_text": {
    fontWeight: 700,
    marginTop: 4,
  },
  ".send-msg-btn": {
    lineHeight: 1.5,
  },
});

export default Quotation;
