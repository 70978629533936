import axios from "axios";
import { API_DOMAIN, DEFAULT_TOKEN } from "constants/schemas";
import { formatError } from "helpers/error";
import { showNotification } from "helpers/notification";

const http = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // ...(DEFAULT_TOKEN ? { Authorization: '' } : {}),
    ...(DEFAULT_TOKEN ? { Authorization: DEFAULT_TOKEN } : {}),
  },
  withCredentials: true,
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMsg = formatError(error)?.message || "Something went wrong";
    showNotification(errorMsg);
    return Promise.reject(error);
  }
);

export default http;
