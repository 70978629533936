import { GetSuggestedPriceProductType } from "types/supplier";
import http from "./http";

const ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiGetUnseenRecommended = () =>
  http.get(`${ENDPOINT}.supplier.count_unseen_recommended`);

export const apiGetCountNotiMenuBar = () =>
  http.get(`${ENDPOINT}.supplier.count_noti_menu_bar`);

export const apiGetSuggestedPriceProduct = (
  params?: GetSuggestedPriceProductType
) => http.get(`${ENDPOINT}.supplier.get_suggested_price_product`, { params });

export const apiGetGuildlineSteps = () =>
  http.get(`${ENDPOINT}.supplier.get_guideline_steps`);

export const apiTrackGuildlineStep = (idx: number) =>
  http.get(`${ENDPOINT}.supplier.track_guideline_step`, {
    params: { step_idx: idx },
  });

export const apiGetQuotationDetails = (quotation_id: string) =>
  http.get(`${ENDPOINT}.supplier.get_quotation_details`, {
    params: { quotation_id },
  });
