import { SVGProps } from "react";

export function SendIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  stroke = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <g clipPath="url(#clip0_12582_177719)">
        <path
          d="M18.0764 2.34619L9.25316 11.1694M18.0764 2.34619L12.4616 18.3884L9.25316 11.1694M18.0764 2.34619L2.03418 7.96096L9.25316 11.1694"
          stroke={stroke}
          strokeWidth="1.60422"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12582_177719">
          <rect
            width="19.2506"
            height="19.2506"
            fill="white"
            transform="translate(0.429688 0.741943)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
