export * from "./dashboard.icon";
export * from "./recommendation.icon";
export * from "./cart.icon";
export * from "./inquiry.icon";
export * from "./bag.icon";
export * from "./package.icon";
export * from "./setting.icon";
export * from "./edit.icon";
export * from "./delete.icon";
export * from "./arrow.icon";
export * from "./mobile.icon";
export * from "./email.icon";
export * from "./contact.icon";
export * from "./copy.icon";
export * from "./check.icon";
export * from "./regenerate.icon";
export * from "./info.icon";
export * from "./lock.icon";
export * from "./flag.icon";
export * from "./bell.icon";
export * from "./close.icon";
export * from "./chat.icon";
export * from "./applications.icon";
export * from "./note.icon";
export * from "./reload.icon";
export * from "./phone.icon";
export * from "./eye.icon";
export * from "./add.icon";
export * from "./send.icon";
export * from "./target.icon";
export * from "./link.icon";
export * from "./crown.icon";
export * from "./sale.icon";
export * from "./filter.icon";
export * from "./calendar";

export const GOOGLE_ICON = require("./google.svg").default;
export const POST_RFQ_ICON = require("./postRfq.svg").default;
export const FRESHDI_LOGO_ICON = require("./logo.svg").default;
export const GREEN_QR_ICON = require("./green_qr.svg").default;
export const RED_WARNING_ICON = require("./red_warning.svg").default;
export const GREEN_COMPLETED_ICON = require("./green_completed.svg").default;
export const YELLOW_CROWN = require("./yellow_crown.svg").default;
export const VERIFIED_ICON = require("./verified.svg").default;
export const UNVERIFIED_ICON = require("./unverified2.svg").default;
export const THUNDER_ICON = require("./thunder.svg").default;
export const SUCESSFUL_ICON = require("./success.svg").default;
export const GREEN_CIRLCE_BOX_ICON = require("./green_circle_box.svg").default;
export const GREEN_CIRLCE_USER_ICON =
  require("./green_circle_user.svg").default;
export const REJECT_ICON = require("./reject.svg").default;
export const SEND_MAIL_ICON = require("./send_email.svg").default;
export const CIRCLE_CROWN_ICON = require("./circle_crown.svg").default;
export const CIRCLE_UNVERIFY_ICON = require("./circle_unverify.svg").default;
export const STARTED_CROWN_ICON = require("./started_crown.svg").default;
export const ADVANCE_CROWN_ICON = require("./advance_crown.svg").default;
export const PRO_CROWN_ICON = require("./pro_crown.svg").default;
export const ORANGE_LIGHT_ICON = require("./orange_light.svg").default;
export const GRAY_SUCESSFUL_ICON = require("./gray_success.svg").default;
export const CIRCLE_VERIFIED_ICON = "/icons/verified.svg";

export const APP_ICONS = {
  planPremium: "/icons/plan_premium.png",
  planAdvance: "/icons/plan_advance.png",
  planSuperior: "/icons/plan_superior.png",
  crown: "/icons/crown.svg",
  calendar: "/icons/calendar.svg",
};
