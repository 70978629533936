import { UPDATE_USER_POINT } from "services/redux/actionTypes";
import { USER_ACTIONS } from "../actions/user";

const initialState = {
  user: null,
  userStatus: "pending",
  reloadContactCredit: false,
  company: null,
  firebaseToken: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        user: action.payload,
        userStatus: action.payload?.userStatus,
      };
    }

    case UPDATE_USER_POINT: {
      return {
        ...state,
        user: {
          ...state.user,
          balance: { ...(state.user?.balance || {}), balance: action.payload },
        },
      };
    }

    case USER_ACTIONS.updateUserContactQuote: {
      return {
        ...state,
        user: {
          ...state.user,
          balance: {
            ...(state.user?.balance || {}),
            contact_quota: action.payload,
          },
        },
      };
    }

    case USER_ACTIONS.updateUserCreditChat: {
      return {
        ...state,
        user: {
          ...state.user,
          balance: {
            ...(state.user?.balance || {}),
            conversation_quota: action.payload,
          },
        },
      };
    }

    case "SET_COMPANY": {
      return {
        ...state,
        company: action.payload,
      };
    }

    case "LOGOUT_USER": {
      return {
        ...state,
        user: null,
        company: null,
      };
    }

    case "UPDATE_FIREBASE_TOKEN": {
      return {
        ...state,
        firebaseToken: action.payload,
      };
    }

    case USER_ACTIONS.reloadContactCredit: {
      return {
        ...state,
        reloadContactCredit: !state?.reloadContactCredit,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
