import http from "./http";

const PRE_ENDPOINT = "/api/method/freshdi.api.web.v3";

type GetAllRFQType = {
  publish_status?: string;
  offset?: number;
  limit?: number;
};

export const apiGetMyQuotations = (data: GetAllRFQType) =>
  http.post(`${PRE_ENDPOINT}.rfq.get_my_quotations`, data);

export const apiGetMyQuotationDetail = (id?: string) =>
  http.post(`${PRE_ENDPOINT}.rfq.get_my_quotation_details`, { rfq_id: id });

export const apiCreateQuotation = (data: any) =>
  http.post(`${PRE_ENDPOINT}.rfq.create_quotation`, data);

export const apiCreateQuotationViaGg = (data: any) =>
  http.post(`${PRE_ENDPOINT}.rfq.create_quotation_via_google`, data);

export const apiGetAllRFQMarket = (data?: any) =>
  http.post(`${PRE_ENDPOINT}.rfq.get_rfqs`, data);

export const apiGetRFQDetail = (id: string) =>
  http.post(`${PRE_ENDPOINT}.rfq.get_rfq_details`, { rfq_id: id });

export const apiGetSuggestedRFQs = (id: string) =>
  http.get(`${PRE_ENDPOINT}.rfq.get_suggested_rfqs`, {
    params: { rfq_id: id },
  });

export const apiGetBizCardRfq = (data: any) =>
  http.post(`${PRE_ENDPOINT}.request.create_get_biz_card_request_rfq`, data);

export const apiGenerateQuotationTitle = (data?: any) =>
  http.post(`${PRE_ENDPOINT}.rfq.generate_quotation_title`, data);

export const apiGenerateQuotationDetails = (data: any) =>
  http.post(`${PRE_ENDPOINT}.rfq.generate_quotation_details`, data);

export const apiFeaturedRFQs = (data: any) =>
  http.post(`${PRE_ENDPOINT}.rfq.get_featured_rfqs`, data);

export const apiMarkRFQSeen = (id: string) =>
  http.put(`${PRE_ENDPOINT}.rfq.mark_as_seen`, { rfq_id: id });

export const apiGetSimilarRfqs = (rfq_id: string) =>
  http.get(`${PRE_ENDPOINT}.rfq.get_similar_rfqs`, { params: { rfq_id } });
