import { SVGProps } from "react";

export function CalendarIcon({
  width = 15,
  height = 16,
  viewBox = "0 0 15 16",
  xmlns = "http://www.w3.org/2000/svg",
  fill = "#F79009",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M12.75 2H12V1.25C12 0.8 11.7 0.5 11.25 0.5C10.8 0.5 10.5 0.8 10.5 1.25V2H4.5V1.25C4.5 0.8 4.2 0.5 3.75 0.5C3.3 0.5 3 0.8 3 1.25V2H2.25C0.975 2 0 2.975 0 4.25V5H15V4.25C15 2.975 14.025 2 12.75 2ZM0 13.25C0 14.525 0.975 15.5 2.25 15.5H12.75C14.025 15.5 15 14.525 15 13.25V6.5H0V13.25ZM11.25 8C11.7 8 12 8.3 12 8.75C12 9.2 11.7 9.5 11.25 9.5C10.8 9.5 10.5 9.2 10.5 8.75C10.5 8.3 10.8 8 11.25 8ZM11.25 11C11.7 11 12 11.3 12 11.75C12 12.2 11.7 12.5 11.25 12.5C10.8 12.5 10.5 12.2 10.5 11.75C10.5 11.3 10.8 11 11.25 11ZM7.5 8C7.95 8 8.25 8.3 8.25 8.75C8.25 9.2 7.95 9.5 7.5 9.5C7.05 9.5 6.75 9.2 6.75 8.75C6.75 8.3 7.05 8 7.5 8ZM7.5 11C7.95 11 8.25 11.3 8.25 11.75C8.25 12.2 7.95 12.5 7.5 12.5C7.05 12.5 6.75 12.2 6.75 11.75C6.75 11.3 7.05 11 7.5 11ZM3.75 8C4.2 8 4.5 8.3 4.5 8.75C4.5 9.2 4.2 9.5 3.75 9.5C3.3 9.5 3 9.2 3 8.75C3 8.3 3.3 8 3.75 8ZM3.75 11C4.2 11 4.5 11.3 4.5 11.75C4.5 12.2 4.2 12.5 3.75 12.5C3.3 12.5 3 12.2 3 11.75C3 11.3 3.3 11 3.75 11Z"
        fill={fill}
      />
    </svg>
  );
}
