import { getCountryFlag } from "helpers/address";
import { Img } from "./Img";
import { Typography, TypographyProps, styled } from "@mui/material";
import Row from "./Row";

const CountryFlag = ({
  country,
  country_code,
  ...props
}: TypographyProps & {
  country?: string;
  country_code?: string;
}) => {
  if (!country) return null;

  return (
    <StyledCompanyFlag spacing={0.5} className="country-box">
      {country_code && (
        <Img src={getCountryFlag(country_code).src} alt="flag" />
      )}
      <Typography
        {...props}
        className={"country_flag ellipsis-text " + props.className}
      >
        {country}
      </Typography>
    </StyledCompanyFlag>
  );
};

const StyledCompanyFlag = styled(Row)({
  p: {
    fontSize: 12,
    fontWeight: 500,
  },
  width: "fit-content",
  padding: "3px 10px",
  borderRadius: 32,
  border: "0.5px solid #EAECF0",
  img: {
    width: 12,
    height: 9,
    borderRadius: 8,
  },
});

export default CountryFlag;
