import { Box, BoxProps, Dialog, Stack, styled } from "@mui/material";
import { LockIcon, UnLockIcon } from "assets/icons";
import { BaseButton, LoadingSkeleton, OpenTabLink, TextMd } from "components";
import { COLORS } from "constants/layout";
import { PATHS } from "constants/routes";
import { lazy, Suspense, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useIntl } from "react-intl";
const MsgUnlockPopup = lazy(() => import("./UnlockPopup"));

const Lock = (
  props: BoxProps & {
    title?: string;
    lockType?: "common" | "msg";
    onReload?: () => void;
    conversationId?: string;
  }
) => {
  const {
    title = "to_unlock_premium_supplier_upgrade_your_plan_now",
    lockType = "common",
    onReload,
    conversationId,
    ...rest
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const t = useIntl();

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onOpenDialog = () => setShowDialog(true);

  return (
    <StyledLock justifyContent="center" {...rest}>
      <div className="lock-box">
        <LockIcon onClick={onOpenDialog} />
      </div>

      <Dialog open={showDialog} onClose={onCloseDialog}>
        {lockType === "common" && (
          <StyledUnlock spacing={2}>
            <Stack direction="row" spacing={1.5}>
              <UnLockIcon style={{ marginTop: 8 }} className="unluck-icon" />
              <TextMd>
                {t.formatMessage({
                  id: title,
                })}
              </TextMd>
              <IoMdClose className="close-icon" onClick={onCloseDialog} />
            </Stack>
            <div className="btn-box">
              <OpenTabLink href={PATHS.supplierPricing}>
                <BaseButton>
                  {t.formatMessage({ id: "upgrade_now" })}
                </BaseButton>
              </OpenTabLink>
            </div>
          </StyledUnlock>
        )}
        <Suspense fallback={<LoadingSkeleton />}>
          {lockType === "msg" && (
            <MsgUnlockPopup
              onClose={onCloseDialog}
              {...{ onReload, conversationId }}
            />
          )}
        </Suspense>
      </Dialog>
    </StyledLock>
  );
};

const StyledLock = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  ".lock-box": {
    padding: "8px 9px",
    borderRadius: 8,
    border: "1px solid #EAECF0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 1px 2px 0px #1018280D",
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    ".lock-box": {
      padding: "7px 9px",
    },
  },
}));

const StyledUnlock = styled(Stack)({
  borderRadius: 12,
  padding: 16,
  maxWidth: 380,
  ".btn-box": {
    textAlign: "center",
    button: {
      color: COLORS.green[600],
      borderColor: COLORS.green[600],
      height: 35,
      maxWidth: 123,
      fontSize: 14,
      margin: "0 auto",
      padding: "8px 12px",
      fontWeight: 500,
    },
  },
  ".close-icon": {
    minWidth: 22,
    height: 22,
    cursor: "pointer",
  },
  ".unluck-icon": {
    minWidth: 25,
  },
});

export default Lock;
