import { SUPPLIER_PLANS } from "constants/data";
import { useMemo } from "react";
import { Img } from "./Img";

export const PlanChip = ({ plan }: { plan?: string }) => {
  const currentPlan = useMemo(
    () => SUPPLIER_PLANS.find((p) => p.label === plan),
    [plan]
  );

  if (!currentPlan?.label2) return null;

  return <Img src={currentPlan.planIcon} alt="icon" height={22} />;
};
