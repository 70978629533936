import { Button, IconButton, styled } from "@mui/material";
import { COLORS } from "constants/layout";

export const StyledMailButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  zIndex: 100,
  right: 2,
  bottom: 64,
  "&,:hover": {
    backgroundColor: "var(--green-500)",
  },
  width: 40,
  height: 40,
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.down("md")]: {
    //   right: 8,
  },
}));

export const StyledChatIcon = styled(IconButton)({
  padding: 0,
});

const ButtonBase = styled(Button)({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: 400,
  padding: "6px 16px",
  fontSize: 14,
});

export const StyledGrayButton = styled(ButtonBase)({
  backgroundColor: COLORS.gray[100],
  border: `1px solid ${COLORS.green[600]}`,
  color: COLORS.green[600],
});

export const StyledWhiteButton = styled(ButtonBase)({
  border: `1px solid ${COLORS.gray[800]}`,
  color: COLORS.gray[900],
});
