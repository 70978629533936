import { useMediaQuery } from "@mui/material";
import { BREAKPOINT_VALUES } from "constants/layout";
import { useMemo } from "react";

const useMediaBreakpoints = () => {
  const mobilePoint = BREAKPOINT_VALUES.sm;
  const tabletPoint = BREAKPOINT_VALUES.md;

  const downMobileBreakpoint = useMediaQuery(
    `(min-width:0px) and (max-width:${mobilePoint}px)`
  );

  const downTabletBreakpoint = useMediaQuery(
    `(min-width:0px) and (max-width:${tabletPoint}px)`
  );

  return useMemo(
    () => ({
      downMobileBreakpoint,
      downTabletBreakpoint,
      isMobile: downTabletBreakpoint,
    }),
    [downMobileBreakpoint, downTabletBreakpoint]
  );
};

export default useMediaBreakpoints;
