import { memo, useMemo } from "react";
import { XsTextMd } from "./Text";
import { StatusType } from "types/common";
import { useIntl } from "react-intl";
import { styled } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";

export const ProductStatus = memo(({ status }: { status?: StatusType }) => {
  const t = useIntl();
  const configs = useMemo(() => {
    switch (status?.value) {
      case "0":
        return {
          label: "pending",
          className: "gray2-label",
          dotColor: "var(--gray-700)",
        };

      case "1":
        return {
          label: "on_display",
          className: "green-label",
          dotColor: "var(--brand-500)",
        };

      case "2":
        return {
          label: "rejected",
          className: "red-label",
          dotColor: "var(--error-500)",
        };

      case "3":
        return {
          label: "offline",
          className: "bgray-label",
          dotColor: "var(--b-gray-500)",
        };

      default:
        return null;
    }
  }, [status]);

  if (!configs) return null;

  return (
    <StyledXsTextMd className={"status-label " + configs.className}>
      <FiberManualRecord style={{ color: configs.dotColor }} />

      {configs.label && t.formatMessage({ id: configs.label })}
    </StyledXsTextMd>
  );
});

export const DotStatus = memo(({ status }: { status?: StatusType }) => {
  const t = useIntl();
  const configs = useMemo(() => {
    switch (status?.value) {
      case "0":
        return {
          label: "on_display",
          className: "green-label",
          dotColor: "var(--brand-500)",
        };

      default:
        return null;
    }
  }, [status]);

  if (!configs) return null;

  return (
    <StyledXsTextMd className={"status-label " + configs.className}>
      <FiberManualRecord style={{ color: configs.dotColor }} />

      {t.formatMessage({ id: status?.label || configs.label })}
    </StyledXsTextMd>
  );
});

const StyledXsTextMd = styled(XsTextMd)({
  svg: {
    height: 8,
    width: 8,
  },
});
