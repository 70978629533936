import { dateDiff } from "helpers/datetime";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

type Props = {
  startTime: string;
  endTime: string;
  type?: string;
};

type TimeType = { type: string; value: number };

const TimeDiff = ({ startTime, endTime, type = "mwdhm" }: Props) => {
  const intl = useIntl();
  const [timediff, setDatediff] = useState<TimeType[]>([]);
  useEffect(() => {
    let dateDiffObj = {};

    try {
      dateDiffObj = dateDiff(startTime, endTime, type);
    } catch (error) {
      console.log(error);
      return;
    }

    type keyType = keyof typeof dateDiffObj;
    const objKeys = Object.keys(dateDiffObj)
      .filter((key: string) => dateDiffObj[key as keyType] > 0)
      .map((key) => ({ type: key, value: dateDiffObj[key as keyType] }));

    setDatediff(objKeys.length > 0 ? objKeys : [{ type: "minute", value: 1 }]);
  }, [startTime, endTime]);

  return (
    <>
      {timediff.map(
        (timeType) =>
          `${timeType.value} ${intl.formatMessage({
            id: timeType.value > 1 ? `${timeType.type}s` : timeType.type,
          })} `
      )}
    </>
  );
};

export default TimeDiff;
