import { SVGProps } from "react";

export function SmDownArrowIcon({
  width = 19,
  height = 18,
  viewBox = "0 0 19 18",
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M5 6.75L9.5 11.25L14 6.75"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowDownIcon({
  width = 14,
  height = 14,
  viewBox = "0 0 14 14",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M7.66683 1.16663V12.8333M7.66683 12.8333L13.5002 6.99996M7.66683 12.8333L1.8335 6.99996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowUpIcon({
  width = 14,
  height = 14,
  viewBox = "0 0 14 14",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M7.00008 12.8333V1.16663M7.00008 1.16663L1.16675 6.99996M7.00008 1.16663L12.8334 6.99996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AnalyticArrowIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M23 6L13.5 15.5L8.5 10.5L1 18M23 6H17M23 6V12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LeftArrowIcon({
  width = 24,
  height = 25,
  viewBox = "0 0 24 25",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M15 18.5L9 12.5L15 6.5"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RebackArrowIcon({
  width = 12,
  height = 12,
  viewBox = "0 0 12 12",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M4.5 7L2 4.5M2 4.5L4.5 2M2 4.5H8C8.53043 4.5 9.03914 4.71071 9.41421 5.08579C9.78929 5.46086 10 5.96957 10 6.5V10"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DirectArrowIcon({
  width = 18,
  height = 18,
  viewBox = "0 0 18 18",
  stroke = "#026AA2",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M13.5 9.75V14.25C13.5 14.6478 13.342 15.0294 13.0607 15.3107C12.7794 15.592 12.3978 15.75 12 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V6C2.25 5.60218 2.40804 5.22064 2.68934 4.93934C2.97064 4.65804 3.35218 4.5 3.75 4.5H8.25M11.25 2.25H15.75M15.75 2.25V6.75M15.75 2.25L7.5 10.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RightArrowIcon({
  width = 5,
  height = 9,
  viewBox = "0 0 5 9",
  stroke = "#98A2B3",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M0.625 8.36523L4.375 4.61523L0.625 0.865234"
        stroke={stroke}
        strokeWidth="0.866667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LongRightArrowIcon({
  width = 12,
  height = 12,
  viewBox = "0 0 12 12",
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M0.75 6H11.25M11.25 6L6 0.75M11.25 6L6 11.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
