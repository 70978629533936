import { Tab, Tabs as TabsLib, TabsProps, styled } from "@mui/material";
import { COLORS, SCROLL_TOP_POSITIONS } from "constants/layout";
import { PATHS } from "constants/routes";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

const Tabs = styled(TabsLib)({
  minHeight: 36,
  // boxShadow: "0px 1px 3px 0px #1018281A, 0px 1px 2px 0px #1018280D",
  ".MuiTabs-flexContainer": {
    borderRadius: 6,
    width: "fit-content",
    boxShadow: "0px 1px 3px 0px #1018281a, 0px 1px 2px 0px #1018280f",
    overflow: "hidden",
  },
  ".MuiButtonBase-root": {
    padding: "8px 12px",
    textTransform: "initial",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 500,
    color: "var(--gray)",
    minHeight: 36,
  },
  ".MuiButtonBase-root.Mui-selected, .MuiButtonBase-root.Mui-selected:hover": {
    color: "#fff",
    backgroundColor: "#027B30",
  },
  ".MuiButtonBase-root:hover": {
    color: "#027B30",
  },
  ".MuiTabs-scroller": {
    padding: "4px 0",
  },
  ".MuiTabs-indicator": {
    display: "none",
  },
  ".MuiButtonBase-root:not(last-child)": {},
});

export const SourcingTabs = (props: TabsProps) => {
  const t = useIntl();
  const { pathname, search } = useLocation();
  const categoryClass = pathname.split("/")[1];

  const tabs = [
    {
      label: "buyers",
      value: PATHS.buyers,
    },
    {
      label: "rfqs",
      value: PATHS.sourcingRequests,
    },
  ];

  const onClickTab = (href: string) => {
    window.location.href = href + search;
  };

  return (
    <StyledSourcingTabs value={categoryClass} {...props}>
      {tabs.map(({ label, value }) => (
        <Tab
          onClick={() => onClickTab(value)}
          key={label}
          label={t.formatMessage({ id: label })}
          value={value.replace("/", "")}
        />
      ))}
    </StyledSourcingTabs>
  );
};

const StyledSourcingTabs = styled(Tabs)(({ theme }) => ({
  position: "sticky",
  top: SCROLL_TOP_POSITIONS.desktopHeader,
  backgroundColor: COLORS.app.bg,
  zIndex: 5,
  [theme.breakpoints.down("md")]: {
    top: 140,
  },
}));

export default Tabs;
