import { Link, MenuItem, Stack, StackProps, styled } from "@mui/material";
import Row from "./Row";
import IntlMsg from "./IntlMsg";
import { useCommonData } from "providers/common-data";
import { memo, useMemo } from "react";
import { PATHS } from "constants/routes";
import { FRESHDI_HOME_DOMAIN } from "constants/schemas";
import { FiUsers, FiUser } from "react-icons/fi";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import Menu from "./Menu";
import { OpenTabLink, SmTextMd, TextSemibold } from "./Text";
import useNavigate from "hooks/useNavigate";
import { useIntl } from "react-intl";
import { useLanguageContext } from "providers/mul-language";
import { CIRCLE_CROWN_ICON } from "assets/icons";
import { Img } from "./Img";
import { addUtmParam } from "helpers/search";

const HeaderServices = (props: StackProps) => {
  const intl = useIntl();
  const { appConfigs } = useCommonData();
  const { openNewTab } = useNavigate();
  const { downTabletBreakpoint } = useMediaBreakpoints();
  const { language } = useLanguageContext();

  const services = useMemo(
    () => [
      {
        label: <IntlMsg id="for_supplier" />,
        icon: <FiUsers />,
        options: [
          {
            label: intl.formatMessage({ id: "buying_lead" }),
            href: PATHS.home,
          },
          {
            label: intl.formatMessage({ id: "pricing" }),
            href: PATHS.supplierPricing,
          },
          {
            label: <IntlMsg id="SellerCentral" />,
            href: PATHS.supplierCenter,
          },
          {
            label: intl.formatMessage({ id: "FAQs" }),
            href: PATHS.supplierFAQs,
          },
        ],
      },
      {
        label: <IntlMsg id="for_buyer" />,
        icon: <FiUser />,
        options: [
          {
            label: intl.formatMessage({ id: "post_sourcing_request" }),
            href: addUtmParam(`${FRESHDI_HOME_DOMAIN}/quick-rfq`),
          },
          {
            label: intl.formatMessage({ id: "pricing" }),
            href: PATHS.buyerPricing,
          },
          {
            label: <IntlMsg id="BuyerCentral" />,
            href: PATHS.buyerCenter,
          },
          {
            label: intl.formatMessage({ id: "FAQs" }),
            href: PATHS.buyerFAQs,
          },
        ],
      },
    ],
    [appConfigs?.all_rfqs, language]
  );

  const onDirectHref = (href?: string) => {
    if (href) openNewTab(href);
  };

  if (!downTabletBreakpoint) {
    return (
      <StyledHeaderServices direction="row" {...props}>
        <OpenTabLink href={PATHS.supplierPricing} className="app-link-btn">
          <SmTextMd className="premium-text">
            <Img src={CIRCLE_CROWN_ICON} alt="icon" />
            {intl.formatMessage({ id: "premium_membership_origin" })}
          </SmTextMd>
        </OpenTabLink>

        {services.map((service, i) => (
          <Menu
            buttonText={service.label}
            buttonProps={{ className: "app-link-btn" }}
            key={i}
          >
            {service.options.map((o, i) => (
              <MenuItem key={i} onClick={() => onDirectHref(o.href)}>
                {o.label}
              </MenuItem>
            ))}
          </Menu>
        ))}
      </StyledHeaderServices>
    );
  }

  return (
    <StyledHeaderServices justifyContent="space-between" {...props}>
      {services.map((service, i) => (
        <Row key={i}>
          <Link underline="none" target={"_blank"} color="#344054">
            <Row direction="column" spacing="12px" className="service_link">
              <SmTextMd className="label" align="center">
                {service.label}
              </SmTextMd>
            </Row>
          </Link>
        </Row>
      ))}
    </StyledHeaderServices>
  );
};

const StyledHeaderServices = styled(Stack)({
  ".value": {
    padding: "2px 8px",
    backgroundColor: "#F04438",
    fontSize: 12,
    color: "#fff",
    borderRadius: 16,
  },
  svg: {
    width: 20,
    height: 20,
    color: "#fff",
  },
  "a p.premium-text": {
    color: "var(--yellow-500)",
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  ".app-link-btn": {
    fontSize: 14,
    "&:hover, &:hover svg": {
      color: "var(--gray-700)",
    },
  },
  ".label": {
    // whiteSpace: "nowrap",
    color: "#667085",
  },
});

export default memo(HeaderServices);
